<template>
  <div>
    <div>
      <b-container fluid>
          <b-row>
              <b-col>
                  <b-card v-show="false">
                      <h3 class="text-white t-shadow">{{ $t('externalUserIrrigation.hello')}} <span class="text-info t-shadow" v-if="this.$i18n.locale === 'bn'">{{ authUser.name_bn }}</span> <span class="text-info t-shadow" v-if="this.$i18n.locale === 'en'">{{ authUser.name }}</span>!</h3>
                      <h3 class="text-white t-shadow" v-if="this.$i18n.locale === 'bn'"><span class="text-danger">কৃষি মন্ত্রণালয়</span> এর <span class="text-warning">ইন্টিগ্রেটেড ডিজিটাল পরিষেবা ডেলিভারি প্ল্যাটফর্ম</span> এ আপনাকে স্বাগতম</h3>
                      <h3 class="text-white t-shadow" v-if="this.$i18n.locale === 'en'">Welcome to <span class="text-warning">Integrated Digital Service Delivery Platform Service</span> of <span class="text-danger">Ministry of Agriculture</span></h3>
                      <h4 class="text-white t-shadow">{{ $t('externalUserIrrigation.your_all_service_here') }} <i class="ion-heart text-danger"></i><div data-icon="i" class="icon"></div></h4>
                  </b-card>
              </b-col>
          </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { grantApplicantDetails } from '../../api/routes'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      loadingState: false,
      overlay: true,
      user: {}
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    authUser () {
       return this.$store.state.Auth.authUser
    }
  },
  created () {
    // this.getGrantApplicantDetails()
    this.$router.push({ name: 'external_user.grant_panel.profile_view' })
  },
  mounted () {
    core.index()
  },
  methods: {
    getGrantApplicantDetails () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const loadingState = { loading: false, listReload: false }
      const params = Object.assign({ applicant_id: this.$store.state.Auth.authUser.id })
      RestApi.getData(incentiveGrantServiceBaseUrl, grantApplicantDetails, params).then(response => {
        if (response.success) {
          this.$store.dispatch('ExternalUserIrrigation/setGrantApplicantDetails', response.data)
        }
        loadingState.listReload = false
        this.$store.dispatch('mutateCommonProperties', loadingState)
      })
    }
  }
}
</script>

<style scoped>
.min-height {
  height: 100vh;
}
.my-card {
  min-height: 100vh;
  background-image: url("../../../../../assets/images/login/bg1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.t-shadow{
  text-shadow: 1px 1px 1px #000;
}
</style>
